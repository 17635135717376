<template>
  <div>
    <div class="expand" @click="expand">{{ $t("developer.subscriptionServiceList.alarm") }}</div>
    <div>
      <vm-table ref="vmTable" :table-data="tableData" :total="total" :page-size="pageSize" :loading="loading"
        :current-page="currentPage" :show-toolbar="false" :show-add="false" @page-change="changePage">
        <el-table-column prop="elevatorName" :label="$t('monitor.elevatorName')" min-width="250" show-overflow-tooltip
          align="center">
          <template slot-scope="scope">
            <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">
              <span style="color: #409EFF">{{ scope.row.elevatorName }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="alarmType" :label="$t('monitor.alarmType')" width="90">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.alarmType === 0" type="danger">{{ $t("monitor.elevatorStop") }}</el-tag>
            <el-tag v-if="scope.row.alarmType === 1" type="danger">{{ $t("monitor.elevatorStranded") }}</el-tag>
            <el-tag v-if="scope.row.alarmType === 2" type="warning">{{ $t("monitor.alarmBellsAlarm") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="duration" :label="$t('monitor.continueTime')" show-overflow-tooltip min-width="150"
          align="center">
          <template slot-scope="scope">
            {{ formatMinutes(scope.row.duration) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('monitor.operation')" width="175px">
          <template slot-scope="scope">
            <el-button type="success" @click="openMonitor(scope.row.elevatorId)">
              监控
            </el-button>
            <el-button @click="openWorkOrder(scope.row.workOrderId)">
              急修工单
            </el-button>
          </template>
        </el-table-column>
      </vm-table>
    </div>
  </div>
</template>

<script>
import VmTable from "../../components/VmTable.vue";

export default {
  components: { VmTable },
  data() {
    return {
      loading: false,
      currentPage: 1,
      pageSize: 6,
      total: 0,
      tableData: [],
      expandFlag: false,
    };
  },
  mounted() {
    this.getList(1);
    setInterval(() => this.getList(this.currentPage), 5000);
  },
  methods: {
    getList(pageIndex) {
      this.currentPage = pageIndex;
      this.loading = true;
      let params = {
        pageIndex,
        pageSize: "6",
      };
      this.$api.getList("/monitorCenter", params).then(response => {
        this.loading = false;
        let data = response.data;
        this.tableData = data.records;
        this.total = data.total;
      }).catch(() => {
        this.loading = false;
      });
    },
    changePage(pageIndex) {
      this.getList(pageIndex);
    },
    expand() {
      this.expandFlag = !this.expandFlag;
      this.$emit("clickExpandTable");
    },
    openMonitor(elevatorId) {
      window.open("#/singleMonitor/" + elevatorId);
    },
    openWorkOrder(workOrderId) {
      window.open("#/workOrder/" + workOrderId);
    },
    formatMinutes(minutes) {
      let day = parseInt(Math.floor(minutes / 1440));
      let hour = day > 0 ? Math.floor((minutes - day * 1440) / 60) : Math.floor(minutes / 60);
      let minute = hour > 0 ? Math.floor(minutes - day * 1440 - hour * 60) : minutes;
      let time = "";
      if (day > 0) time += day + "天";
      if (hour > 0) time += hour + "小时";
      if (minute > 0) time += minute + "分钟";
      return time;
    },
  },
};
</script>

<style lang="scss" scoped>
.expand {
  width: 38px;
  height: 15px;
  margin-left: 45%;
  background-color: #2D8CF0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  color: white;
  padding: 10px 5px;
  cursor: pointer;
  text-align: center;
}
</style>
