<template>
  <div id="googleMap"></div>
</template>
<script>
import googleMapUtil from "@/util/googleMapUtil";

let map;
export default {
  components: {},
  props: {
    cityName: {
      type: Array,
    },
  },
  data() {
    return {
      map: "",
      markers: [],
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    createMap() {
      googleMapUtil.initMap("googleMap").then(map => {
        this.map = map;
      });
    },
    addMarker(data) {
      if (this.markers) {
        this.markers.forEach(marker => {
          marker.setMap(null);
        });
      }
      let markers = [];
      let bounds = new window.google.maps.LatLngBounds();
      data.forEach((item) => {
        let iconUrl;
        if (item.monitorState === 3) {
          iconUrl = "/static/images/baiDuMap/shuidi_red_small.png";
        } else if (item.monitorState === 2) {
          iconUrl = "/static/images/baiDuMap/shuidi_grey_small.png";
        } else {
          iconUrl = "/static/images/baiDuMap/shuidi_blue_small.png";
        }
        let Center;
        if (item.lat < 90 && item.lat > -90 && item.lng < 180 && item.lng > -180) {
          Center = new window.google.maps.LatLng(item.lat, item.lng);
        } else {
          return true;
        }
        let marker = new window.google.maps.Marker({
          position: Center,
          icon: iconUrl,
        });
        markers.push(marker);
        marker.setMap(this.map);
        bounds.extend(Center);
        window.google.maps.event.addListener(marker, "click", function () {
          window.open("#/MarkToUseUnit/" + item.useUnitId + "," + item.useUnitName);
        });
        let opts = "<div style='line-height:1.8em;font-size:12px;'>" + item.useUnitName + "</div>";
        let infoWindow = new window.google.maps.InfoWindow({ content: opts });
        window.google.maps.event.addListener(marker, "mouseover", function () {
          infoWindow.open(marker.get("map"), marker);
        });
        window.google.maps.event.addListener(marker, "mouseout", function () {
          infoWindow.close(marker.get("map"), marker);
        });
      });
      this.markers = markers;
      this.map.fitBounds(bounds);
    },
  },
};
</script>
<style lang="scss" scoped></style>
