<template>
  <div id="baiDuMap"></div>
</template>
<script>
let BMapGL = window.BMapGL;
let mapvgl = window.mapvgl;

export default {
  components: {},
  props: {
    cityName: {
      type: Array,
    },
  },
  data() {
    return {
      map: "",
      view: "",
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    createMap() {
      try {
        let map = new BMapGL.Map("baiDuMap");
        let point = new BMapGL.Point(116.403748, 39.915055);
        map.enableScrollWheelZoom();
        map.centerAndZoom(point, 5);
        let scaleCtrl = new BMapGL.ScaleControl();
        map.addControl(scaleCtrl);
        let zoomCtrl = new BMapGL.ZoomControl({ anchor: window.BMAP_ANCHOR_TOP_LEFT });
        map.addControl(zoomCtrl);
        let navi3DCtrl = new BMapGL.NavigationControl3D();
        map.addControl(navi3DCtrl);
        this.map = map;
      } catch (e) {
        console.log(e);
      }
    },
    addMarker(data) {
      if (this.view) {
        this.view.removeAllLayers();
      }
      this.view = new mapvgl.View({
        map: this.map,
      });
      let layerData = [];
      if (data && data.length) {
        data.forEach((item) => {
          if (!item.lng || !item.lat) {
            return;
          }
          let iconUrl;
          if (item.monitorState === 3) {
            iconUrl = "/static/images/baiDuMap/shuidi_red_small.png";
          } else if (item.monitorState === 2) {
            iconUrl = "/static/images/baiDuMap/shuidi_grey_small.png";
          } else {
            iconUrl = "/static/images/baiDuMap/shuidi_blue_small.png";
          }
          layerData.push({
            geometry: {
              type: "Marker",
              coordinates: [item.lng, item.lat],
            },
            properties: {
              icon: iconUrl,
              useUnitId: item.useUnitId,
              useUnitName: item.useUnitName,
            },
          });
        });
      }
      let layer = new mapvgl.IconLayer({
        width: 100 / 4,
        height: 153 / 4,
        offset: [0, -153 / 8],
        opacity: 0.8,
        enablePicked: true,
        selectedIndex: -1,
        selectedColor: "#00ff77",
        autoSelect: true,
        onClick: (e) => {
          if (e.dataIndex < 0) {
            return false;
          }
          window.open("#/MarkToUseUnit/" + e.dataItem.properties.useUnitId + "," + e.dataItem.properties.useUnitName);
        },
        onMousemove: (e) => {
          if (e.dataIndex < 0) {
            this.map.clearOverlays();
            return false;
          }
          this.map.clearOverlays();
          let point = new window.BMapGL.Point(e.dataItem.geometry.coordinates[0], e.dataItem.geometry.coordinates[1]);
          let label = new BMapGL.Label(e.dataItem.properties.useUnitName, {
            position: point,
            offset: new BMapGL.Size(5, -42),
          });
          label.setStyle({
            color: "#000",
            fontSize: "15px",
            border: "0",
          });
          this.map.addOverlay(label);
        },
      });
      this.view.addLayer(layer);
      layer.setData(layerData);
    },
  },
};
</script>
<style lang="scss" scoped></style>