<template>
  <div class="vm-table">
    <div v-if="showToolbar" class="tools-bar"  id="vm-table-bar">
      <el-button
        v-if="showAdd"
        class="add"
        type="primary"
        icon="el-icon-plus"
        @click="handleAddClick">
        {{$t("common.add")}}
      </el-button>
      <slot name="otherButton"></slot>
      <el-input
        ref="searchInput"
        v-model.trim="filter"
        class="search-bar"
        :placeholder="$t('common.'+searchPlaceholder)"
        clearable
        @keyup.enter.native="handleSearchClick">
        <el-button slot="append" icon="el-icon-search" @click="handleSearchClick"></el-button>
      </el-input>
      <el-button
        v-if="showAdSearch"
        class="ad-search-show-btn"
        type="primary"
        plain
        @click="showAdSearchContent = !showAdSearchContent">
        {{$t("common.advanceSearch")}}
        <i class="el-icon-arrow-down el-icon--right" :class="{ 'arrow-rotate': showAdSearchContent }"></i>
      </el-button>
    </div>
    <div v-if="!showToolbar" class="tools-bar"  id="vm-table-bar1"></div>
    <el-collapse-transition v-if="showAdSearch">
      <div v-show="showAdSearchContent" class="ad-search">
        <el-button class="ad-search-btn" type="primary" icon="el-icon-search" @click="handleAdSearchClick">
          {{$t("common.search")}}
        </el-button>
        <div class="ad-search-content">
          <slot name="adSearch"></slot>
        </div>
      </div>
    </el-collapse-transition>
    <el-table
      v-loading="loading"
      border
      ref="elTable"
      stripe
      :size="size"
      :data="tableData"
      :height="height"
      :max-height="myMaxHeight"
      @selection-change="handleSelectionChange">
      <el-table-column
        v-if="multiSelection"
        type="selection"
        width="55">
      </el-table-column>
      <slot></slot>
    </el-table>
    <div v-if="showPage" style="margin-top: 16px;overflow: hidden">
      <el-pagination
        class="page"
        background
        :page-size="pageSize"
        :current-page="currentPage"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      tableData: {
        type: Array,
        default() {
          return [];
        },
      },
      total: {
        type: Number,
        default: 0,
      },
      pageSize: {
        type: Number,
        default: 10,
      },
      currentPage: {
        type: Number,
        default: 1,
      },
      searchPlaceholder: {
        type: String,
        default: "search",
      },
      size: {
        type: String,
        default: "mini",
      },
      pageLayout: {
        type: String,
        default: "total, prev, pager, next, jumper",
      },
      showAdd: {
        type: Boolean,
        default: true,
      },
      showToolbar: {
        type: Boolean,
        default: true,
      },
      showPage: {
        type: Boolean,
        default: true,
      },
      multiSelection: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      height: [String],
      showAdSearch: Boolean,
    },
    data() {
      return {
        myMaxHeight: 0,
        showAdSearchContent: false,
        filter: "",
      };
    },
    mounted() {
      this.changeMaxHeight();
      window.onresize = () => {
        this.changeMaxHeight();
      };
    },
    methods: {
      handleCurrentChange(page) {
        this.$emit("page-change", page);
      },
      handleSearchClick() {
        this.$emit("search-click", this.filter);
      },
      handleAdSearchClick() {
        this.$emit("ad-search-click");
      },
      handleAddClick(event) {
        this.$emit("add-click", event);
      },
      handleSelectionChange(val) {
        this.$emit("selection-change", val);
      },
      changeMaxHeight() {
        if (this.maxHeight) {
          this.myMaxHeight = this.maxHeight;
        } else {
          let bar = document.getElementById("vm-table-bar");
          if (bar == null){
            bar = document.getElementById("vm-table-bar1");
          }
          this.barHeight = bar.offsetHeight;
          let clientHeight = document.body.clientHeight;
          if (this.parentName === "ElDialog") {
            this.myMaxHeight = clientHeight - this.barHeight - 220;
          } else {
            this.myMaxHeight = clientHeight - this.barHeight - 155;
          }
        }
        this.$nextTick(() => {
          this.$refs.elTable.$el.style["max-height"] = this.myMaxHeight + "px";
        });
      },
    },
  };
</script>

<style lang="scss">
@import "../style/variables.scss";

.vm-table {
  .tools-bar {
    padding-bottom: 16px;
    overflow: hidden;

    .search-bar {
      float: right;
      right: 0;
      width: 30%;
      min-width: 290px;

      i {
        cursor: pointer;
      }
    }

    .ad-search-show-btn {
      float: right;
      margin-right: 15px;

      .el-icon-arrow-down {
        transition: All 0.4s ease-in-out;
      }

      .arrow-rotate {
        transform: rotate(180deg);
      }
    }

    .add {
      i {
        padding-right: 4px;
      }
    }
  }

  .ad-search {
    position: relative;
    background-color: #F4F7FA;
    border-radius: 4px;
    border: 1px solid #ECEEF3;

    .ad-search-content {
      padding: 22px 115px 0 15px;
    }

    .ad-search-btn {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .page {
    float: right;
  }
}

.spin-container {
  position: relative;

  .loading {
    @include gravityCenter;
    background: rgba(255, 255, 255, 0.7);;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
</style>
